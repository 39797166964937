body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* :root {
  --color-1: black;
  --main2-bg-color: rgb(11, 9, 11);
  --text1-color: rgb(255, 255, 255);

  --color-2: rgb(50, 50, 50);
  --neon-color-1: rgb(255, 35, 35);
  --neon-color-2: rgba(255, 35, 35, 0.733);
  --neon-color-3: white;
  --neon-color-4: white;;

  --fadein-dur: 1.5s;

  --roadmap-spacing: 60px;
  --roadmap-initial-spacing: 35px;
}

@font-face {
  font-family: myFont;
  src: url(./fonts/HWYGOTH.TTF)
}


.myFont {
  font-family: myFont;
}

.mint-Container{
  font-family: myFont !important;
} */

/* .connect-button{
  font-family: myFont !important;
  font-size: 30px !important;
  color: var(--neon-color-1);
} */

